<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span> {{ $t('create') }} {{ $tc('entity.company', 1) }} </span>
    </div>
    <el-form
      ref="formCompany"
      v-loading="loading"
      :model="company"
      label-position="top"
      :rules="rulesCompany"
    >
      <el-row :gutter="20">
        <el-col :sm="8">
          <el-form-item :label="$t('companyName')" prop="companyName">
            <el-input
              v-model="company.companyName"
              type="text"
              :maxlength="160"
            ></el-input>
          </el-form-item>
          <el-form-item prop="companyIdentification">
            <span slot="label"
              >{{ $t('identification') }}
              <el-tooltip
                content="Ingrese un número sin puntos, ni comas, ni guiones, ni espacios"
                placement="right"
              >
                <el-button size="mini" type="text">
                  <i class="text-info far fa-question-circle"></i>
                </el-button>
              </el-tooltip>
            </span>
            <el-input v-model="company.companyIdentification" :maxlength="24">
              <template slot="append"> - {{ dv }} </template>
            </el-input>
          </el-form-item>
          <el-form-item
            :label="$t('identificationType')"
            prop="identificationTypeId"
          >
            <select-identification-type
              v-model="company.identificationTypeId"
            ></select-identification-type>
          </el-form-item>
          <el-form-item :label="$tc('country', 1)" prop="countryId">
            <select-country
              v-model="company.countryId"
              @set-name="setCountryName"
            ></select-country>
          </el-form-item>
          <el-form-item :label="$t('region')" prop="companyRegion">
            <select-region
              v-if="company.countryId === 'CO'"
              v-model="company.regionId"
              @set-name="setRegionName"
            ></select-region>
            <el-input
              v-if="company.countryId !== 'CO'"
              v-model="company.companyRegion"
              :maxlength="80"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$tc('city', 1)" prop="companyCity">
            <select-city
              v-if="company.countryId === 'CO'"
              v-model="company.cityId"
              :selected-region="company.regionId"
              @set-name="setCityName"
            ></select-city>
            <el-input
              v-if="company.countryId !== 'CO'"
              v-model="company.companyCity"
              :maxlength="80"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('personType')" prop="personTypeId">
            <el-select v-model="company.personTypeId">
              <el-option value="1" label="Persona Juridica"></el-option>
              <el-option value="2" label="Persona Natural"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('regimeType')" prop="regimeTypeId">
            <el-select v-model="company.regimeTypeId">
              <el-option
                value="48"
                label="Impuesto sobre las ventas – IVA"
              ></el-option>
              <el-option value="49" label="No responsable de IVA"></el-option>
              <!-- <el-option value="05" label="Régimen Ordinario"></el-option>
              <el-option value="04" label="Régimen Simple"></el-option> -->
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="8">
          <el-form-item :label="$t('website')">
            <el-input
              v-model="company.companyWebsite"
              type="url"
              :maxlength="160"
              class="input-with-select"
            >
              <el-select slot="prepend" v-model="urlType">
                <el-option label="http://" value="http://"></el-option>
                <el-option label="https://" value="https://"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('phone')">
            <el-input
              v-model="company.companyPhone"
              type="text"
              :maxlength="24"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$tc('address', 1)">
            <el-input
              v-model="company.companyAddress"
              type="text"
              :maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('postalCode')">
            <el-input
              v-model="company.companyZipCode"
              type="text"
              :maxlength="16"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('currency')" prop="currencyId">
            <select-currency-global
              v-model="company.currencyId"
            ></select-currency-global>
          </el-form-item>
          <el-form-item :label="$t('dateFormat')">
            <el-select v-model="company.companyDateFormat">
              <el-option-group>
                <el-option
                  value="MM-DD-YYYY"
                  label="[MM-DD-YYYY] 03-25-2019"
                ></el-option>
                <el-option
                  value="MM/DD/YYYY"
                  label="[MM/DD/YYYY] 03/25/2019"
                ></el-option>
                <el-option
                  value="MM.DD.YYYY"
                  label="[MM.DD.YYYY] 03.25.2019"
                ></el-option>
              </el-option-group>
              <el-option-group>
                <el-option
                  value="DD-MM-YYYY"
                  label="[DD-MM-YYYY] 25-03-2019"
                ></el-option>
                <el-option
                  value="DD/MM/YYYY"
                  label="[DD/MM/YYYY] 25/03/2019"
                ></el-option>
                <el-option
                  value="DD.MM.YYYY"
                  label="[DD.MM.YYYY] 25.03.2019"
                ></el-option>
              </el-option-group>
              <el-option-group>
                <el-option
                  value="YYYY-MM-DD"
                  label="[YYYY-MM-DD] 2019-03-25"
                ></el-option>
                <el-option
                  value="YYYY/MM/DD"
                  label="[YYYY/MM/DD] 2019/03/25"
                ></el-option>
                <el-option
                  value="YYYY.MM.DD"
                  label="[YYYY.MM.DD] 2019.03.25"
                ></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('timeFormat')">
            <el-select v-model="company.companyTimeFormat">
              <el-option value="HH:mm" label="[24 H] 20:25"></el-option>
              <el-option value="hh:mm a" label="[12 H] 08:25 pm"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="8">
          <el-form-item :label="$t('timeZone')" prop="timeZoneId">
            <select-time-zone v-model="company.timeZoneId"></select-time-zone>
          </el-form-item>
          <el-form-item :label="$t('culture')" prop="cultureId">
            <select-culture v-model="company.cultureId"></select-culture>
          </el-form-item>
          <el-form-item :label="$t('language')" prop="languageId">
            <select-language v-model="company.languageId"></select-language>
          </el-form-item>
          <el-form-item :label="$tc('isPartner', 1)">
            <el-switch
              v-model="company.companyIsPartner"
              @change="isPartner"
            ></el-switch>
          </el-form-item>
          <el-form-item
            v-if="!company.companyIsPartner"
            :label="$tc('partner', 1)"
          >
            <select-partner
              v-model="company.idParent"
              @set-partner="setPartner"
            ></select-partner>
          </el-form-item>
          <el-form-item
            v-if="!company.companyIsPartner"
            :label="$tc('invoiceToPartner', 1)"
          >
            <el-switch v-model="company.companyInvoiceToPartner"></el-switch>
          </el-form-item>
          <el-form-item
            v-if="!company.companyInvoiceToPartner"
            :label="$tc('person', 1) + ' (Ingrese el ID)'"
          >
            <el-input
              v-model="company.commonPersonId"
              :maxlength="36"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-card shadow="hover">
        <div slot="header" class="clearfix">
          <span>Usuario</span>
        </div>
        <el-row :gutter="20">
          <el-col :sm="6">
            <el-form-item label="Nombre de usuario" prop="userName">
              <el-input
                v-model="company.userName"
                :maxlength="32"
                placeholder="Ejemplo: pedroperez"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="6">
            <el-form-item :label="$t('email')" prop="userEmail">
              <el-input
                v-model="company.userEmail"
                type="email"
                :maxlength="160"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="6">
            <el-form-item :label="$t('firstName')" prop="userFirstName">
              <el-input
                v-model="company.userFirstName"
                :maxlength="64"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="6">
            <el-form-item :label="$t('lastName')" prop="userLastName">
              <el-input
                v-model="company.userLastName"
                :maxlength="64"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-card>
      <br />
      <el-card shadow="hover">
        <div slot="header" class="clearfix">
          <span>Plan</span>
        </div>
        <el-row :gutter="20">
          <el-col :sm="10">
            <el-form status-icon label-position="top">
              <el-form-item label="Servicio">
                <select-service v-model="company.serviceId"></select-service>
              </el-form-item>
              <el-form-item label="Plan">
                <select-service-plan
                  v-model="plan"
                  :service-id="company.serviceId"
                  :init-blank="true"
                  @set-plan="getFeatures"
                ></select-service-plan>
              </el-form-item>
              <el-form-item label="Ciclo">
                <el-select v-model="company.companyServiceFrequencyBilling">
                  <el-option :label="$t('monthly')" value="monthly"></el-option>
                  <el-option :label="$t('yearly')" value="yearly"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="
                  'Tarifa ' +
                  $options.filters.formatToCurrency(
                    company.companyServiceFrequencyBilling === 'yearly'
                      ? plan.planPriceYearly
                      : plan.planPriceMonthly,
                  )
                "
              >
                <el-input
                  v-model.number="company.companyServicePrice"
                ></el-input>
              </el-form-item>
              <el-form-item label="Vendedor">
                <select-user-is-seller
                  vm-model="company.vendorUserId"
                ></select-user-is-seller>
              </el-form-item>
              <el-form-item label="Fecha primera factura">
                <el-date-picker
                  v-model="company.companyServiceBillingStartDate"
                  type="date"
                  value-format="yyyy/MM/dd"
                  format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="Fecha proxima factura">
                <el-date-picker
                  v-model="company.companyServiceDateNextInvoice"
                  type="date"
                  value-format="yyyy/MM/dd"
                  format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="Fecha fin del periodo de prueba">
                <el-date-picker
                  v-model="company.companyServiceEndDateTrialPeriod"
                  type="date"
                  value-format="yyyy/MM/dd"
                  format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="Observacion">
                <el-input
                  v-model="company.companyServiceNote"
                  placeholder="Observacion"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :sm="14">
            <el-table
              :data="company.features"
              stripe
              :v-loading="loadingFeatures"
            >
              <el-table-column
                property="featureName"
                label="Caracteristica"
                width="200"
              >
                <template slot-scope="scope">
                  {{ scope.row.featureName }}
                  <el-tooltip
                    v-if="scope.row.featureDescription"
                    effect="dark"
                    placement="right"
                    :content="scope.row.featureDescription"
                  >
                    <span>
                      <i class="fad fa-info-circle text-info"></i>
                    </span>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column property="featureValue" label="Valor">
                <template slot-scope="scope">
                  <el-switch
                    v-if="scope.row.featureType === 'access'"
                    v-model="scope.row.featureValue"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-value="true"
                    inactive-value="false"
                  ></el-switch>
                  <el-input
                    v-if="scope.row.featureType.toLowerCase().includes('limit')"
                    v-model.number="scope.row.featureValue"
                    style="width: 80px"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Presonalizacion">
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.featureType === 'info' ||
                      (scope.row.featureType === 'access' &&
                        scope.row.featureValue === 'true') ||
                      (scope.row.featureType.toLowerCase() === 'openlimit' &&
                        scope.row.featureValue < 0)
                    "
                  >
                    Incluido
                  </span>
                  <el-switch
                    v-if="
                      scope.row.featureType === 'access' &&
                      scope.row.featureValue.toString() !== 'true'
                    "
                    v-model="scope.row.featureAddonQuantity"
                  ></el-switch>
                  <el-input
                    v-if="
                      scope.row.featureType.toLowerCase().includes('limit') &&
                      parseInt(scope.row.featureValue) >= 0
                    "
                    v-model.number="scope.row.featureAddonQuantity"
                    style="width: 80px"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column align="right">
                <template slot-scope="scope">
                  <span
                    v-if="
                      scope.row.featureType === 'access' &&
                      scope.row.featureValue !== 'true' &&
                      scope.row.featureAddonQuantity
                    "
                    >{{
                      scope.row.featureUnitPriceCustom | formatToCurrency
                    }}
                    Mensual</span
                  >
                  <span
                    v-if="
                      scope.row.featureType.toLowerCase().includes('limit') &&
                      parseInt(scope.row.featureValue) >= 0
                    "
                    >{{
                      (scope.row.featureAddonQuantity *
                        scope.row.featureUnitPriceCustom)
                        | formatToCurrency
                    }}
                    Mensual</span
                  >
                </template>
              </el-table-column>
              <el-table-column align="right">
                <template slot-scope="scope">
                  <span
                    v-if="company.companyServiceFrequencyBilling === 'monthly'"
                  >
                    {{
                      (scope.row.featureAddonQuantity *
                        scope.row.featureUnitPriceCustom)
                        | formatToCurrency
                    }}
                  </span>
                  <span
                    v-if="company.companyServiceFrequencyBilling === 'yearly'"
                  >
                    {{
                      (scope.row.featureAddonQuantity *
                        scope.row.featureUnitPriceCustom *
                        12)
                        | formatToCurrency
                    }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <br />
            <h4 style="float=right;text-align:right;">
              Total {{ $t(company.companyServiceFrequencyBilling) }} :
              {{ total | formatToCurrency }}
            </h4>
          </el-col>
        </el-row>
      </el-card>
      <br />
      <el-form-item>
        <el-button size="mini" type="primary" @click="save">
          {{ $t('save') }}
        </el-button>
        <router-link
          to="/companies"
          class="el-button el-button--text el-button--small"
          tag="button"
        >
          {{ $t('cancel') }}
        </router-link>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { calcularDv } from '../../assets/js/nbxr'
import { i18n } from '../../i18n'
import companyMix from './company-mix'
import model from './model'

export default {
  name: 'CompanyCreate',
  mixins: [companyMix],
  data() {
    return {
      company: { ...model() },
      urlType: 'http://',
      loading: false,
      loadingFeatures: false,
      hasLogo: false,
      plan: '',
      rulesCompany: {
        companyName: [
          { required: true, message: i18n.t('required'), trigger: 'blur' },
        ],
        companyIdentification: [
          { required: true, message: i18n.t('required'), trigger: 'blur' },
        ],
        identificationTypeId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        companyCity: [
          { required: true, message: this.$t('required'), trigger: 'blur' },
        ],
        companyRegion: [
          { required: true, message: this.$t('required'), trigger: 'blur' },
        ],
        countryId: [
          {
            required: true,
            message: this.$t('required'),
            trigger: 'blur,change',
          },
        ],
        currencyId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        timeZoneId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        cultureId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        languageId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        personTypeId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        regimeTypeId: [
          { required: true, message: i18n.t('required'), trigger: 'change' },
        ],
        userFirstName: [
          { required: true, message: i18n.t('required'), trigger: 'blur' },
        ],
        userLastName: [
          { required: true, message: i18n.t('required'), trigger: 'blur' },
        ],
        userName: [
          { required: true, message: i18n.t('required'), trigger: 'blur' },
        ],
        userEmail: [
          { required: true, message: i18n.t('required'), trigger: 'blur' },
          {
            type: 'email',
            message: i18n.t('invalidEmail'),
            trigger: ['blur', 'change'],
          },
        ],
      },
    }
  },
  computed: {
    dv() {
      return calcularDv(this.company.companyIdentification)
    },
    total() {
      // console.log('Total----')
      const _this = this
      _this.loading = true
      let total =
        _this.company.companyServiceFrequencyBilling === 'yearly'
          ? _this.plan.planPriceYearly
          : _this.plan.planPriceMonthly
      for (let i = 0; i < _this.company.features.length; i++) {
        if (
          _this.company.features[i].featureAddonQuantity &&
          _this.company.features[i].featureAddonQuantity.toString() !== 'false'
        ) {
          let value = parseFloat(
            _this.company.features[i].featureUnitPriceCustom,
          )
          if (
            !isNaN(_this.company.features[i].featureAddonQuantity.toString())
          ) {
            value =
              parseFloat(_this.company.features[i].featureUnitPriceCustom) *
              parseFloat(_this.company.features[i].featureAddonQuantity)
          }
          if (
            _this.company.companyServiceFrequencyBilling.toLowerCase() ===
            'yearly'
          ) {
            value *= 12
          }
          total += value
        }
      }
      _this.company.companyServicePrice = total
      _this.loading = false

      return total
    },
  },
  methods: {
    save() {
      const _this = this
      _this.$refs.formCompany.validate(valid => {
        if (valid) {
          _this.loading = true
          _this.company.companyWebsite =
            _this.urlType + _this.company.companyWebsite
          _this.company.companyIdentificationCheck = calcularDv(
            _this.company.companyIdentification,
          )
          _this.$http
            .post('companies/register', {
              company: _this.company,
              companyService: _this.company,
              user: _this.company,
            })
            .then(
              response => {
                _this.$swal(i18n.t('ok'), i18n.t('processSuccess'), 'success')
                _this.company = { ...model() }
                _this.loading = false
              },
              response => {
                _this.loading = false
              },
            )
        } else {
          _this.$message.error('Oops, hay datos requeridos que debes ingresar!')

          return false
        }
      })
    },
    setPartner(value) {
      const _this = this
      _this.loading = true
      if (value) {
        _this.company.idParent = value.id
        _this.company.companyKeyParent = value.companyKey
      } else {
        _this.company.idParent = ''
        _this.company.companyKeyParent = ''
      }
      _this.loading = false
    },
    isPartner(value) {
      const _this = this
      _this.loading = true
      if (!value) {
        _this.company.idParent = ''
        _this.company.companyKeyParent = ''
      }
      _this.company.companyInvoiceToPartner = false
      _this.loading = false
    },
    getFeatures(plan) {
      const _this = this
      _this.company.features = []
      _this.company.companyServicePrice = 0
      if (plan) {
        _this.company.planId = plan.id
        _this.loadingFeatures = true
        _this.$http.get(`plans/${plan.id}`).then(
          response => {
            _this.loadingFeatures = false
            if (response.body.features) {
              response.body.features.forEach((feature, index, array) => {
                if (
                  response.body.features[index].featureType.toLowerCase() ===
                  'access'
                ) {
                  response.body.features[index].featureValue =
                    feature.featureValue === 'true'
                }
              })
              _this.company.features = response.body.features
            }
          },
          response => {
            _this.loadingFeatures = false
          },
        )
      }
    },
  },

  // mounted: function () {
  // }
}
</script>
