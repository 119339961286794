//
// import { i18n } from '../../i18n'
//
export default {
  data() {
    return {}
  },
  methods: {
    setCountryName(name) {
      // console.log('countryNameeee---', name)
      this.loading = true
      this.company.companyCountry = name
      this.company.companyRegion = ''
      this.company.companyCity = ''
      this.company.regionId = ''
      this.company.cityId = ''
      this.loading = false
    },
    setRegionName(name) {
      // console.log('setRegionName')
      this.loading = true
      this.company.companyRegion = name
      this.company.companyCity = ''
      this.company.cityId = ''
      this.loading = false
    },
    setCityName(name) {
      // console.log('setCityName')
      this.loading = true
      this.company.companyCity = name
      this.loading = false
    },
  },
  computed: {},
  mounted() {},
}
